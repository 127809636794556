// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-tsx": () => import("./../../../src/pages/about.tsx" /* webpackChunkName: "component---src-pages-about-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../../../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-homies-tsx": () => import("./../../../src/pages/homies.tsx" /* webpackChunkName: "component---src-pages-homies-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-legals-tsx": () => import("./../../../src/pages/legals.tsx" /* webpackChunkName: "component---src-pages-legals-tsx" */),
  "component---src-pages-products-tsx": () => import("./../../../src/pages/products.tsx" /* webpackChunkName: "component---src-pages-products-tsx" */),
  "component---src-pages-redirecting-checkout-tsx": () => import("./../../../src/pages/redirecting-checkout.tsx" /* webpackChunkName: "component---src-pages-redirecting-checkout-tsx" */),
  "component---src-pages-shops-tsx": () => import("./../../../src/pages/shops.tsx" /* webpackChunkName: "component---src-pages-shops-tsx" */),
  "component---src-templates-product-product-tsx": () => import("./../../../src/templates/Product/Product.tsx" /* webpackChunkName: "component---src-templates-product-product-tsx" */)
}

