import React, { useState } from "react";
import { useCookie } from "../../hooks/useCookie";
import { useLocalStorage } from "../../hooks/useLocalStorage";
// import { useLocalStorage } from "../../hooks/useLocalStorage";
import { useWindowSize } from "../../hooks/useWindowSize";
// import { createCart } from "../../api/createCart";

const PageContext = React.createContext({
  cookie: undefined,
  lang: "fr",
  defaultLang: "fr",
  originalPath: "",
  removeCookie: (key: string) => {},
  setLang: (lang: string) => {},
  name: "",
  setName: (name: string) => {},
  size: { width: 0, height: 0 },
  updateCookie: (value: any, options: any) => {},
  cart: { id: "", lines: [] },
  setCart: (value: any) => {},
  user: undefined,
  setUser: (value: any) => {},
  // snipcart: undefined,
});

declare const window: any;

export const PageContextProvider: React.FC<{
  pageContext: any;
  children: any;
}> = ({ pageContext, children }) => {
  const [lang, setLang] = useState<string>(pageContext.lang);
  const [name, setName] = useState<string>(pageContext.lang);
  const [user, setUser] = useState<any>(undefined);
  const [cookie, updateCookie, removeCookie] = useCookie("userToken", "null", {
    maxAge: 1296000000,
    path: "/",
  });
  const [cart, setCart] = useLocalStorage("cart", {
    id: undefined,
    lines: [],
  });
  const [token, setToken] = useLocalStorage("token", {
    token: undefined,
  });

  const size = useWindowSize();
  // const snipcart = useSnipcart();

  return (
    <PageContext.Provider
      value={{
        ...pageContext,
        lang: lang,
        defaultLang: "fr",
        setLang: setLang,
        cookie: cookie,
        updateCookie: updateCookie,
        removeCookie: removeCookie,
        size: size,
        cart: cart,
        setCart: setCart,
        name,
        setName,
        user,
        setUser,
        token,
        setToken,
        // snipcart,
      }}
    >
      {children}
    </PageContext.Provider>
  );
};

export const usePageContext = () => React.useContext(PageContext);
